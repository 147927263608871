import { Button, Flex, Image, Text } from "@mantine/core";
import { X } from "./Social/X";
import { Instagram } from "./Social/Instagram";
import { LinkedIn } from "./Social/LinkedIn";

export const Footer = () => {
  return (
    <Flex
      justify="center"
      align="center"
      direction="column"
      className="bg-white p-6 sm:p-16"
    >
      <Image
        src="/assets/podsie-full_logo-green.png"
        alt="Podsie Logo"
        className="max-w-40 mb-4"
      />
      <Text component="p" className="font-normal text-center mb-6">
        Podsie is a part of the Teaching Lab Innovation Studio
      </Text>
      <Flex justify="center" align="center" mb="md">
        <X />
        <Instagram />
        <LinkedIn />
      </Flex>
      <Flex justify="center" align="center" wrap="wrap">
        <Flex direction="row" justify="center" align="center">
          <Button
            variant="transparent"
            color="green"
            component={"a"}
            href="mailto:hello@teachinglab.com"
            target="_blank"
            className="mb-6"
          >
            Contact Us
          </Button>
          <Button
            variant="transparent"
            color="green"
            component={"a"}
            href="https://www.podsie.org/terms-and-conditions"
            className="mb-6"
          >
            Terms of Use
          </Button>
        </Flex>
        <Flex direction="row" justify="center" align="center">
          <Button
            variant="transparent"
            color="green"
            component={"a"}
            href="https://www.podsie.org/privacy-policy"
            className="mb-6"
          >
            Privacy Policy
          </Button>
          <Button
            variant="transparent"
            color="green"
            component={"a"}
            href="https://www.podsie.org/data-processing-agreement"
            className="mb-6"
          >
            Data Processing
          </Button>
        </Flex>
      </Flex>
      <Text component="p" className="font-light text-center mb-4">
        © 2024 Podsie
      </Text>
    </Flex>
  );
};
