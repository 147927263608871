import type { MetaFunction } from "@remix-run/node";
import { Cta } from "~/components/LandingPage/Cta";
import { Footer } from "~/components/LandingPage/Footer";
import { HeroSection } from "~/components/LandingPage/HeroSection";
import { HowPodsieWorks } from "~/components/LandingPage/HowPodsieWorks";
import PodsiePrinciples from "~/components/LandingPage/PodsiePrinciples";
import ProductFeatures from "~/components/LandingPage/ProductFeatures";
import { Testimonials } from "~/components/LandingPage/Testimonials";
import AppLayout from "~/layouts/_app";

export const meta: MetaFunction = () => {
  return [
    { title: "Podsie" },
    {
      name: "description",
      content: "Podsie helps your students remember what they learn!",
    },
  ];
};
export default function Home() {
  return (
    <AppLayout navRoutes={[]}>
      <HeroSection />
      <HowPodsieWorks />
      <ProductFeatures />
      <PodsiePrinciples />
      <Testimonials />
      <Cta />
      <Footer />
    </AppLayout>
  );
}
