import { Box, Flex, Grid, Group, Image, Text } from "@mantine/core";
import SignUpButton from "../Auth/SignUpButton";

const HeroH1 = () => {
  return (
    <Text
      component="h1"
      className="text-5xl font-bold text-text-primary leading-tight mb-6 p-0"
    >
      Empower your students to{" "}
      <Text
        component="span"
        variant="gradient"
        gradient={{ from: "secondary", to: "primary", deg: 90 }}
        className="text-5xl font-bold leading-tight m-0 p-0"
      >
        remember what they learned
      </Text>
    </Text>
  );
};

const HeroSubtitle = () => {
  return (
    <Text className="text-slate-900 text-lg font-medium">
      Podsie helps teachers improve knowledge retention through research-backed,
      personalized review.
    </Text>
  );
};

const HeroInfoColumn = () => {
  return (
    <Flex direction="column" justify="center" className="p-0 h-full">
      <HeroH1 />
      <HeroSubtitle />
      <HeroCta />
    </Flex>
  );
};

const HeroCta = () => {
  return (
    <Group className="mt-6">
      <SignUpButton type="teacher" />
      <SignUpButton type="student" />
      {/* <ContactButton buttonText="Request a demo" /> */}
    </Group>
  );
};

export const HeroSection = () => {
  return (
    <Box className="bg-gradient-to-t from-secondary-50 to-white flex justify-center items-center">
      <Grid
        maw={1440}
        px={{ base: 40, md: 80 }}
        pt={80}
        pb={{ base: 20, md: 80 }}
        justify="center"
        align="stretch"
        gutter="xl"
      >
        <Grid.Col span={{ base: 12, sm: 5 }}>
          <HeroInfoColumn />
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 7 }}>
          <Image
            src="/assets/hero-image.svg"
            alt="Student studying on laptop"
          />
        </Grid.Col>
      </Grid>
    </Box>
  );
};
