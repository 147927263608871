import { Flex, Grid, Image, Text } from "@mantine/core";
type FeatureData = {
  title: string;
  description: string;
  image: string;
  alt: string;
};
const features: FeatureData[] = [
  {
    title: "Quick Standards-Aligned Content Generation",
    description:
      "Effortlessly generate standards-aligned questions with our AI generator. Save time while ensuring your assignments meet curriculum goals.",
    image: "/assets/ai-question-generator-modal.png",
    alt: "A form for generating questions with AI with fields for instructions, reference materials, and standards",
  },
  {
    title: "Personalized Practice for Long Time Retention",
    description:
      "Boost learning retention with personalized review sessions. Spaced retrieval focuses on areas where students need the most practice, reinforcing understanding.",
    image: "/assets/student-question.png",
    alt: "A review queston form, showing a question, the selected answer, and an explanation",
  },
  {
    title: "AI-Powered Explanations Supports Concept Comprehension",
    description:
      "Support students in real time with PodBot. Our AI assistant explains questions and topics, offering clarity when students need it most.",
    image: "/assets/podbot.png",
    alt: "An interactive form where a student told the AI assistant 'PodBot' that they don't understand the question, and PodBod gives an explanation of the core concepts in the question",
  },
  {
    title: "Track, Analyze, and Support with Ease",
    description:
      "Understand mastery levels and identify struggling areas with intuitive performance insights. Podsie’s data makes it easy to guide students where they need help most.",
    image: "/assets/assignment-table-data-2.png",
    alt: "A data table, showing each student's progress toward a concept, including their engagement and mastery",
  },
];

const FeatureDescription = ({ feature }: { feature: FeatureData }) => (
  <Flex direction="column" className="flex flex-col justify-center h-full">
    <Text component="h3" className="text-2xl font-bold text-text-primary mb-4">
      {feature.title}
    </Text>
    <Text className="text-text-secondary font-normal">
      {feature.description}
    </Text>
  </Flex>
);

const Feature = ({ feature }: { feature: FeatureData }) => (
  <Grid gutter="lg">
    <Grid.Col
      order={{ base: 2, sm: 1 }}
      className="flex flex-col"
      span={{ base: 12, sm: 6 }}
    >
      <Image src={feature.image} alt={feature.alt} />
    </Grid.Col>
    <Grid.Col
      order={{ base: 1, sm: 2 }}
      className="flex flex-col"
      span={{ base: 12, sm: 6 }}
    >
      <FeatureDescription feature={feature} />
    </Grid.Col>
  </Grid>
);

const FeatureReversed = ({ feature }: { feature: FeatureData }) => (
  <Grid gutter="lg" align="stretch">
    <Grid.Col className="flex flex-col" span={{ base: 12, sm: 6 }}>
      <FeatureDescription feature={feature} />
    </Grid.Col>
    <Grid.Col className="flex flex-col" span={{ base: 12, sm: 6 }}>
      <Image src={feature.image} alt={feature.alt} className="p0" />
    </Grid.Col>
  </Grid>
);

export default function ProductFeatures() {
  return (
    <Flex className="bg-white p-6 sm:p-16" justify="center" align="center">
      <Flex
        direction="column"
        className="max-w-screen-md "
        gap={{ base: "lg", sm: 60 }}
      >
        {features.map((feature, index) => {
          if (index % 2 === 0) {
            return <FeatureReversed key={feature.title} feature={feature} />;
          }
          return <Feature key={feature.title} feature={feature} />;
        })}
      </Flex>
    </Flex>
  );
}
