import { Flex, Image, SimpleGrid, Text } from "@mantine/core";

const PodsieWorksColumn = (props: {
  heading: string;
  subheading: string;
  imageUrl: string;
  alt: string;
}) => {
  return (
    <Flex direction="column" justify="center" className="p-0 h-full">
      <Image
        src={props.imageUrl}
        alt={props.alt}
        className="w-full max-h-60 mb-2"
        fit="contain"
      />
      <Text component="h3" className="text-lg font-bold text-center mb-2">
        {props.heading}
      </Text>
      <Text className="text-center font-light">{props.subheading}</Text>
    </Flex>
  );
};

const podsieInfo = [
  {
    heading: "Your Assignments, Transformed into Personalized Learnings",
    subheading:
      "Easily create standards-aligned questions with AI, and let Podsie transform completed assignments into tailored review decks for each student.",
    imageUrl: "/assets/assignment-transformed.svg",
    alt: "Pencil writing on paper quiz",
  },
  {
    heading: "Focused Review Where It’s Needed Most",
    subheading:
      "Podsie focuses review sessions on areas where students need the most support, using proven strategies like spaced repetition to reinforce learning.",
    imageUrl: "/assets/focused-review.svg",
    alt: "A question mark and lightbulb float over review cards",
  },
  {
    heading: "Actionable Insights for Better Outcomes",
    subheading:
      "Track student progress with clear data on challenging and mastered topics, helping you provide targeted support where it’s needed most.",
    imageUrl: "/assets/actionable-insights.svg",
    alt: "A chart with graphs",
  },
];

export const HowPodsieWorks = () => {
  return (
    <Flex
      direction="column"
      align="center"
      className="bg-gradient-to-t from-secondary-50 to-white p-6 sm:p-16"
    >
      <Text
        component="h2"
        className="text-center text-4xl font-bold text-text-primary mb-12"
      >
        How Podsie Works
      </Text>
      <SimpleGrid cols={{ base: 1, sm: 3 }} spacing="xl" maw={1440}>
        {podsieInfo.map((info) => (
          <PodsieWorksColumn
            key={info.heading}
            heading={info.heading}
            subheading={info.subheading}
            imageUrl={info.imageUrl}
            alt={info.alt}
          />
        ))}
      </SimpleGrid>
    </Flex>
  );
};
