import { Button } from "@mantine/core";
import { TbBrandLinkedinFilled } from "react-icons/tb";

export const LinkedIn = () => {
  return (
    <Button
      aria-label="Podsie on LinkedIn"
      variant="transparent"
      color="green"
      component={"a"}
      href="https://www.linkedin.com/company/podsie"
      target="_blank"
    >
      <TbBrandLinkedinFilled size={32} color="green" />
    </Button>
  );
};
