import { Button } from "@mantine/core";
import { TbBrandXFilled } from "react-icons/tb";

export const X = () => {
  return (
    <Button
      aria-label="Podsie on 'X/Twitter'"
      variant="transparent"
      color="green"
      component={"a"}
      href="https://x.com/podsieorg"
      target="_blank"
    >
      <TbBrandXFilled size={32} color="green" />
    </Button>
  );
};
