import { Flex, Image, SimpleGrid, Text } from "@mantine/core";

type Principle = {
  imageUrl: string;
  title: string;
  description: string;
  alt: string;
};

const principles: Principle[] = [
  {
    title: "Differentiated & personalized",
    description:
      "Podsie tailors learning to each student's strengths and weaknesses, ensuring they focus only on what they need to review for efficient use of time.",
    imageUrl: "/assets/differentiated-personalized.svg",
    alt: "A stack of review cards",
  },
  {
    title: "Spacing and interleaving",
    description:
      "Spacing out review over time boosts learning retention by 15% compared to cramming. Podsie automates spaced review to support effective, long-term learning.",
    imageUrl: "/assets/spacing-interleaving.svg",
    alt: "A calendar",
  },
  {
    title: "Frequent retrieval",
    description:
      "Our understanding strengthens when we retrieve concepts just before forgetting them. Podsie ensures students review exactly when needed to reinforce learning.",
    imageUrl: "/assets/frequent-retrieval.svg",
    alt: "An illustration of a head with a gear in it",
  },
];

const Principle = ({ principle }: { principle: Principle }) => {
  return (
    <Flex
      key={principle.title}
      direction="column"
      className="justify-center items-center"
    >
      <Image
        src={principle.imageUrl}
        alt={principle.alt}
        className="p-8 max-w-40"
      />
      <Text component="h3" className="text-xl font-semibold text-center">
        {principle.title}
      </Text>
      <Text
        component="p"
        className="text-text-secondary text-lg font-normal text-center"
      >
        {principle.description}
      </Text>
    </Flex>
  );
};

export default function PodsiePrinciples() {
  return (
    <Flex
      justify="center"
      align="center"
      direction="column"
      className="bg-secondary-50 p-6 sm:p-16"
    >
      <Text
        component="h2"
        className="text-2xl font-semibold text-center max-w-3xl"
      >
        Bridging the gap between teaching and lasting retention with learning
        science principles
      </Text>
      <SimpleGrid cols={{ base: 1, sm: 3 }} className="max-w-screen-md">
        {principles.map((principle) => (
          <Principle key={principle.title} principle={principle} />
        ))}
      </SimpleGrid>
    </Flex>
  );
}
