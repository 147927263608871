import { Button } from "@mantine/core";
import { Link, useOutletContext } from "@remix-run/react";
import { OutletContext } from "~/root";

export default function SignUpButton(props: { type: "teacher" | "student" }) {
  const { user } = useOutletContext<OutletContext>();

  if (user) {
    return (
      <Button
        className="h-10"
        component={Link}
        to={props.type === "teacher" ? "/teacher" : "/student"}
        color="primary"
      >
        {props.type === "teacher" ? "Teacher" : "Student"} Site
      </Button>
    );
  }
  return (
    <Button
      className="h-10"
      component={Link}
      to={`/signin?type=${props.type}`}
      color="primary"
    >
      {props.type === "teacher" ? "Teacher" : "Student"} Sign up
    </Button>
  );
}
